const basic = {
  namespaced: true,
  state: {
    pageTitle: "",
  },
  getters: {
    pageTitle: (state) => state.pageTitle,
  },
  mutations: {
    updatePageTitle(state, pageTitle) {
      state.pageTitle = pageTitle;
    },
  },
  actions: {},
};
export default basic;
