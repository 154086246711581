<template>
  <router-view />
</template>

<script>
import { TITLE } from "@/utils/constants/constants";
export default {
  name: "App",
  computed: {
    pageTitle() {
      return this.$store.getters["basic/pageTitle"];
    },
  },
  watch: {
    pageTitle: {
      handler(newV) {
        document.title = newV ? `${newV} - ${TITLE}` : TITLE;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss"></style>
