import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// 解决 NavigationDuplicated: Avoided redundant navigation to current location 报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  /** 首页 **/
  {
    path: "/",
    name: "首页",
    component: () => import("@/views/Index"),
  },
  /** 协议 **/
  {
    path: "/protocols",
    name: "协议",
    component: () => import("@/views/Protocols/Index"),
    children: [
      {
        path: "user-agreement",
        name: "千里商机用户协议",
        component: () => import("@/views/Protocols/UserAgreementView.vue"),
      },
      {
        path: "privacy-policy",
        name: "千里商机隐私政策",
        component: () => import("@/views/Protocols/PrivacyPolicyView.vue"),
      },
      {
        path: "user-agreement-new",
        name: "千里马招投标用户协议",
        component: () => import("@/views/Protocols/UserAgreementViewNew.vue"),
      },
      {
        path: "privacy-policy-new",
        name: "千里马招投标隐私政策",
        component: () => import("@/views/Protocols/PrivacyPolicyViewNew.vue"),
      },
      {
        path: "privacyDesc",
        name: "中间号使用规则",
        component: () => import("@/views/Protocols/privacyDesc.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach(async (to, from, next) => {
  /** 路由发生变化，修改pageTitle **/
  store.commit("basic/updatePageTitle", to.name || to.matched[0].name || "");
  next();
});

export default router;
