import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/fade-in.css";
import "@/assets/normalize.scss";
import { CDN } from "@/utils/constants/constants";

Vue.config.productionTip = false;
console.log("process.env:", process.env);
/** 官网OSS origin **/
Vue.prototype.$cdn = CDN;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
